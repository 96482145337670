import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';

import { HomePage } from '@kali/theme/lib/pages/home/page';

export default class HomeRoute extends React.Component {
  state = {
    isSubmitting: false,
    isSubscribed: false,
    email: '',
  };

  debounce = undefined;

  componentDidMount() {
    if (window.IS_BROWSER === true) {
      const { hash } = window.location;

      if (hash) {
        window.location.replace(hash.substring(1));
      }
    }
  }

  componentWillUnmount() {
    if (this.debounce) {
      clearTimeout(this.debounce);
    }
  }

  onNewsletterSubscribe = e => {
    e.preventDefault();

    const { email } = this.state;
    const body = {
      email,
    };

    this.setState({ isSubmitting: true }, async () => {
      await require('axios').post(
        'https://us-central1-kalina-2ab49.cloudfunctions.net/api/newsletter',
        body
      );

      this.setState({ isSubscribed: true, isSubmitting: false, email: '' });

      this.debounce = setTimeout(() => {
        this.setState({
          isSubscribed: false,
        });
      }, 5000);
    });
  };

  onChange = e => {
    this.setState({
      email: e.target.value,
    });
  };

  render() {
    const { isSubmitting, isSubscribed } = this.state;

    return (
      <Layout>
        <HomePage
          onChange={this.onChange}
          onNewsletterSubscribe={this.onNewsletterSubscribe}
          isSubmitting={isSubmitting}
          isSubscribed={isSubscribed}
        />
      </Layout>
    );
  }
}
